<template>
  <div>
    <vs-row
      vs-type="flex"
      class="mt-0 mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <filtro @atualizarFiltro="atualizarFiltro"></filtro>
      </vs-col>
    </vs-row>
    <div id="doc_table" class="cardTable mb-1 vs-con-loading__container">
      <div v-if="relatorio.length == 0">
        <div class="pt-20 scroll-relatorio con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">
                Nenhum Documento
              </h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="scroll-relatorio">
        <!-- <VuePerfectScrollbar class="scroll-relatorio" :settings="settings"> -->
        <vs-table
          maxHeight="65vh"
          class="px-2"
          pagination
          maxItems="15"
          stripe
          :data="relatorio"
          max-items="100"
          id="tableCliente"
        >
          <template slot="thead">
            <vs-th sort-key="id" style="width: 15%">Colaborador</vs-th>
            <vs-th sort-key="qtd_recebida" id="center" style="width: 10%"
              >Quantidade Recebida</vs-th
            >
            <vs-th sort-key="qtd_usada" id="center" style="width: 12%"
              >Quantidade Usada</vs-th
            >
            <vs-th sort-key="qtd_devolvida" id="center" style="width: 12%"
              >Quantidade Devolvida</vs-th
            >
            <vs-th id="center" style="width: 12%">Quantidade Restante</vs-th>
            <vs-th sort-key="unidade_medida" style="width: 10%"
              >Unidade Medida</vs-th
            >
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="index" v-for="(row, index) in data">
              <vs-td>{{ row.nome }}</vs-td>
              <vs-td class="font-semibold text-center">{{
                row.qtd_recebida ? row.qtd_recebida : 0
              }}</vs-td>
              <vs-td class="text-center">{{
                row.qtd_usada ? row.qtd_usada : 0
              }}</vs-td>
              <vs-td class="text-center">{{
                row.qtd_devolvida ? row.qtd_devolvida : 0
              }}</vs-td>
              <vs-td
                class="text-center"
                :style="
                  row.qtd_recebida - row.qtd_usada - row.qtd_devolvida == 0
                    ? 'color: green'
                    : 'color:red'
                "
                >{{
                  row.qtd_recebida - row.qtd_usada - row.qtd_devolvida
                }}</vs-td
              >
              <vs-td>{{ row.unidade_medida }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- </VuePerfectScrollbar> -->
      </div>
    </div>
    <vx-card no-shadow card-background="dark">
      <vs-row>
        <vs-col
          vs-w="3"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          class="py-1"
        >
          <Badge
            class="mr-2"
            color="dark"
            :text="'Quantidade: ' + relatorio.length"
            size="large"
          ></Badge>
        </vs-col>
        <vs-col
          vs-w="9"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          class="py-1"
        >
          <vs-button icon="info" type="line" color="success" size="small">
            <b style="font-size: 14px"
              >Recebido: {{ totais.qtd_recebida }}&nbsp;{{ unidade_medida }}</b
            >
          </vs-button>
          <vs-button icon="info" type="line" color="success" size="small">
            <b style="font-size: 14px"
              >Usado: {{ totais.qtd_usada }}&nbsp;{{ unidade_medida }}</b
            >
          </vs-button>
          <vs-button icon="info" type="line" color="success" size="small">
            <b style="font-size: 14px"
              >Devolvido: {{ totais.qtd_devolvida }}&nbsp;{{
                unidade_medida
              }}</b
            >
          </vs-button>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import filtro from "./components/filtro"
import components from "@/components/default/exports.js"
export default {
  data () {
    return {
      filtro: {},
      relatorio: [],
      totais: {},
      unidade_medida: ''
    }
  },
  methods: {
    async atualizarFiltro (filtro) {
      this.filtro = await filtro
      await this.getRelatorioEstoque()
    },
    async getRelatorioEstoque () {
      try {
        this.relatorio = await this.$http.post(`getRelatorioEstoque`, this.filtro)
        this.unidade_medida = this.relatorio.length > 0 ? this.relatorio[0].unidade_medida : ""
        this.totais.qtd_recebida = this.relatorio.reduce((total, item) => {
          return total + item.qtd_recebida
        }, 0)
        this.totais.qtd_devolvida = this.relatorio.reduce((total, item) => {
          return total + item.qtd_devolvida
        }, 0)
        this.totais.qtd_usada = this.relatorio.reduce((total, item) => {
          return total + item.qtd_usada
        }, 0)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    }

  },
  async mounted () {
    await this.getRelatorioEstoque()

  },
  components: {
    filtro,
    ...components
  }
}
</script>
<style scoped lang="scss">
.scroll-relatorio {
  height: 72vh !important;
}
thead tr th.center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
